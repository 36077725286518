import React, { useContext, useState } from "react";
import { ToastEffect } from "../../functions/ToastEffect";
import { connectDiscord, signUpToNewsLetter } from "../../API/authAPI";
import UserContext from "../UserContext";
import consoleLog from "../../functions/consoleLog";

// Images && Logo
import ShineRanker from "../../constant/images/ShineRanker.png";

const Social = () => {

    // context method
    const {
        subscriptionData,
        discordData,
        userData,
        newsLetter
    } = useContext(UserContext);

    // console.log("userData", userData);
    // console.log("discordData", discordData);

    // use state
    const [isConnecting, setIsConnecting] = useState(false);

    // handle discord connect function
    const handleDiscordConnect = async (discordID, email) => {

        // console log activity
        consoleLog("Subscription Message:", subscriptionData?.message);

        // trigger loading effect
        setIsConnecting(true);

        // check if discord ID exist
        if (!discordID) {
            window.location.href = `${process.env.REACT_APP_SHINEFY_SERVER}/auth/discord`;
            setIsConnecting(false);
            return;
        }

        // check if user is a subscriber
        if (subscriptionData?.message !== "subscribed") {
            ToastEffect("error", "Only subscribers can get the VIP role; trials are not included.");
            setIsConnecting(false);
            return;
        }

        await connectDiscord(discordID, email, subscriptionData?.status?.item_id)
            .then((response) => {
                // console.log(response?.data);
                consoleLog("Social.js > connectDiscord", response?.data);
                if (!response?.data?.message) {
                    window.open(process.env.REACT_APP_DISCORD_INVITELINK, '_blank');
                    ToastEffect("error", "Join discord channel first.");
                } else {
                    window.location.reload();
                }
                setIsConnecting(false);
            }).catch((error) => {
                // console.log(error);
                consoleLog("Social.js > error", error);
                setIsConnecting(false);
            });
    };

    return (
        <>
            <div className="p-4 mb-4 bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 sm:p-6 dark:bg-gray-800">
                <div className="flow-root">
                    <h3 className="text-xl font-semibold dark:text-white">Connected accounts</h3>
                    <ul className="divide-y divide-gray-200 dark:divide-gray-700">
                        <li className="py-4">
                            <div className="flex items-center space-x-4">
                                <div className="flex-shrink-0 text-gray-800 dark:text-gray-50">
                                    <i className="fa-brands fa-discord text-2xl" />
                                </div>
                                <div className="flex-1 min-w-0">
                                    <span className="block text-base font-semibold text-gray-900 truncate dark:text-white">
                                        Discord
                                    </span>
                                    <div className={`capitalize block text-sm font-normal truncate ${discordData?.discordData ? "text-blue-700 dark:text-blue-500" : "text-gray-700 dark:text-gray-500"} `}>
                                        {discordData?.discordData ? (
                                            <a href={process.env.REACT_APP_DISCORD_INVITELINK} target="_blank" className="hover:underline">#{discordData?.discordData?.username}</a>
                                        ) : "Login Using Discord"}
                                    </div>
                                </div>
                                <div className="inline-flex items-center">
                                    {discordData?.discordData && discordData?.discordData.isConnected ? (
                                        <div className="px-3 py-2 mb-0 mr-3 text-sm font-medium text-center text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:ring-blue-300 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600">
                                            Connected
                                        </div>
                                    ) : (
                                        <button onClick={() => handleDiscordConnect(discordData?.discordData?.discord_id || "", discordData?.discordData?.email || "")} className="px-3 py-2 mb-0 mr-3 text-sm font-medium text-center text-white rounded-lg bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                            {isConnecting ? (<i className="fa-solid fa-spinner fa-spin-pulse" />) : "Connect"}
                                        </button>
                                    )}
                                </div>
                            </div>
                        </li>
                        <li className="py-4">
                            <div className="flex items-center space-x-4">
                                <div className="flex-shrink-0 text-gray-800 dark:text-gray-50">
                                    <img decoding="async" data-nimg="intrinsic" src={ShineRanker} className="w-[2rem] h-[2rem]" alt="Image" />
                                </div>
                                {/* <div className="dark:bg-purple-700 bg-purple-500 p-1 -ml-1 rounded-full">
                                    <img decoding="async" data-nimg="intrinsic" src={ShineRanker} className="w-[1.4rem] h-[1.4rem]" alt="Image" />
                                </div> */}
                                <div className="flex-1 min-w-0">
                                    <span className="block text-base font-semibold text-gray-900 truncate dark:text-white">
                                        Shine Ranker
                                    </span>
                                    <div className="block text-sm font-normal truncate text-gray-700 dark:text-gray-500">
                                        app.shineranker.com
                                    </div>
                                </div>
                                <div className="inline-flex items-center">

                                    <a href="https://app.shineranker.com" target="_blank" className="cursor-pointer px-3 py-2 mb-3 mr-3 text-sm font-medium text-center text-white rounded-lg bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                        Connect
                                    </a>

                                </div>
                            </div>
                        </li>
                        {/* <li className="py-4">
                            <div className="flex items-center space-x-4">
                                <div className="flex-shrink-0 text-gray-800 dark:text-gray-50">
                                    <i className="fa-brands fa-google text-2xl" />
                                </div>
                                <div className="flex-1 min-w-0">
                                    <span className="block text-base font-semibold text-gray-900 truncate dark:text-white">
                                        Google
                                    </span>
                                    <div className="block text-sm font-normal truncate text-gray-700 dark:text-gray-500">
                                        Login Using Google
                                    </div>
                                </div>
                                <div className="inline-flex items-center">

                                    <div className="px-3 py-2 mb-3 mr-3 text-sm font-medium text-center text-white rounded-lg bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                        Connect
                                    </div>

                                </div>
                            </div>
                        </li> */}
                        <li className="py-4">
                            <div className="flex items-center space-x-4">
                                <div className="flex-shrink-0 text-gray-800 dark:text-gray-50">
                                    {/* <i className="fa-brands fa-discord text-2xl" /> */}
                                    <i className="fa-solid fa-envelope-open-text text-2xl" />
                                </div>
                                <div className="flex-1 min-w-0">
                                    <span className="block text-base font-semibold text-gray-900 truncate dark:text-white">
                                        News Letter
                                    </span>
                                    <div className="block text-sm font-normal truncate text-gray-700 dark:text-gray-500">
                                        <span>
                                            Get the latest updates
                                        </span>
                                    </div>
                                </div>
                                <div className="inline-flex items-center">

                                    {/* {discordData?.discordData && discordData?.discordData.isConnected ? (
                                        <div className="px-3 py-2 mb-3 mr-3 text-sm font-medium text-center text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:ring-blue-300 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600">
                                            Connected
                                        </div>
                                    ) : (
                                        <button
                                            onClick={() => handleDiscordConnect(discordData?.discordData?.discord_id || "", discordData?.discordData?.email || "")}
                                            className="px-3 py-2 mb-3 mr-3 text-sm font-medium text-center text-white rounded-lg bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                        >
                                            {isConnecting ? (<i className="fa-solid fa-spinner fa-spin-pulse" />) : "Connect"}
                                        </button>
                                    )} */}

                                    {userData?.UserInfo && userData?.UserInfo?.news_letter ? (
                                        <div className="px-3 py-2 mb-0 mr-3 text-sm font-medium text-center text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:ring-blue-300 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600">
                                            Subscribed
                                        </div>
                                    ) : (
                                        <button
                                            onClick={newsLetter?.handleSignUpNewsLetter}
                                            className="px-3 py-2 mb-0 mr-3 text-sm font-medium text-center text-white rounded-lg bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                        >
                                            {newsLetter?.isSigningUp ? (<i className="fa-solid fa-spinner fa-spin-pulse" />) : "Subscribe"}
                                        </button>
                                    )}

                                </div>
                            </div>
                        </li>

                        {/* <li className="py-4">
                            <div className="flex items-center space-x-4">
                                <div className="flex-shrink-0">
                                    <svg className="w-5 h-5 dark:text-white" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="github" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512"><path fill="currentColor" d="M165.9 397.4c0 2-2.3 3.6-5.2 3.6-3.3.3-5.6-1.3-5.6-3.6 0-2 2.3-3.6 5.2-3.6 3-.3 5.6 1.3 5.6 3.6zm-31.1-4.5c-.7 2 1.3 4.3 4.3 4.9 2.6 1 5.6 0 6.2-2s-1.3-4.3-4.3-5.2c-2.6-.7-5.5.3-6.2 2.3zm44.2-1.7c-2.9.7-4.9 2.6-4.6 4.9.3 2 2.9 3.3 5.9 2.6 2.9-.7 4.9-2.6 4.6-4.6-.3-1.9-3-3.2-5.9-2.9zM244.8 8C106.1 8 0 113.3 0 252c0 110.9 69.8 205.8 169.5 239.2 12.8 2.3 17.3-5.6 17.3-12.1 0-6.2-.3-40.4-.3-61.4 0 0-70 15-84.7-29.8 0 0-11.4-29.1-27.8-36.6 0 0-22.9-15.7 1.6-15.4 0 0 24.9 2 38.6 25.8 21.9 38.6 58.6 27.5 72.9 20.9 2.3-16 8.8-27.1 16-33.7-55.9-6.2-112.3-14.3-112.3-110.5 0-27.5 7.6-41.3 23.6-58.9-2.6-6.5-11.1-33.3 2.6-67.9 20.9-6.5 69 27 69 27 20-5.6 41.5-8.5 62.8-8.5s42.8 2.9 62.8 8.5c0 0 48.1-33.6 69-27 13.7 34.7 5.2 61.4 2.6 67.9 16 17.7 25.8 31.5 25.8 58.9 0 96.5-58.9 104.2-114.8 110.5 9.2 7.9 17 22.9 17 46.4 0 33.7-.3 75.4-.3 83.6 0 6.5 4.6 14.4 17.3 12.1C428.2 457.8 496 362.9 496 252 496 113.3 383.5 8 244.8 8zM97.2 352.9c-1.3 1-1 3.3.7 5.2 1.6 1.6 3.9 2.3 5.2 1 1.3-1 1-3.3-.7-5.2-1.6-1.6-3.9-2.3-5.2-1zm-10.8-8.1c-.7 1.3.3 2.9 2.3 3.9 1.6 1 3.6.7 4.3-.7.7-1.3-.3-2.9-2.3-3.9-2-.6-3.6-.3-4.3.7zm32.4 35.6c-1.6 1.3-1 4.3 1.3 6.2 2.3 2.3 5.2 2.6 6.5 1 1.3-1.3.7-4.3-1.3-6.2-2.2-2.3-5.2-2.6-6.5-1zm-11.4-14.7c-1.6 1-1.6 3.6 0 5.9 1.6 2.3 4.3 3.3 5.6 2.3 1.6-1.3 1.6-3.9 0-6.2-1.4-2.3-4-3.3-5.6-2z"></path></svg>
                                </div>
                                <div className="flex-1 min-w-0">
                                    <span className="block text-base font-semibold text-gray-900 truncate dark:text-white">
                                        Github account
                                    </span>
                                    <span className="block text-sm font-normal text-gray-500 truncate dark:text-gray-400">
                                        Not connected
                                    </span>
                                </div>
                                <div className="inline-flex items-center">
                                    <a href="#" className="px-3 py-2 mb-3 mr-3 text-sm font-medium text-center text-white rounded-lg bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Connect</a>
                                </div>
                            </div>
                        </li>
                        <li className="pt-4 pb-6">
                            <div className="flex items-center space-x-4">
                                <div className="flex-shrink-0">
                                    <svg className="w-5 h-5 dark:text-white" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="dribbble" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M256 8C119.252 8 8 119.252 8 256s111.252 248 248 248 248-111.252 248-248S392.748 8 256 8zm163.97 114.366c29.503 36.046 47.369 81.957 47.835 131.955-6.984-1.477-77.018-15.682-147.502-6.818-5.752-14.041-11.181-26.393-18.617-41.614 78.321-31.977 113.818-77.482 118.284-83.523zM396.421 97.87c-3.81 5.427-35.697 48.286-111.021 76.519-34.712-63.776-73.185-116.168-79.04-124.008 67.176-16.193 137.966 1.27 190.061 47.489zm-230.48-33.25c5.585 7.659 43.438 60.116 78.537 122.509-99.087 26.313-186.36 25.934-195.834 25.809C62.38 147.205 106.678 92.573 165.941 64.62zM44.17 256.323c0-2.166.043-4.322.108-6.473 9.268.19 111.92 1.513 217.706-30.146 6.064 11.868 11.857 23.915 17.174 35.949-76.599 21.575-146.194 83.527-180.531 142.306C64.794 360.405 44.17 310.73 44.17 256.323zm81.807 167.113c22.127-45.233 82.178-103.622 167.579-132.756 29.74 77.283 42.039 142.053 45.189 160.638-68.112 29.013-150.015 21.053-212.768-27.882zm248.38 8.489c-2.171-12.886-13.446-74.897-41.152-151.033 66.38-10.626 124.7 6.768 131.947 9.055-9.442 58.941-43.273 109.844-90.795 141.978z"></path></svg>
                                </div>
                                <div className="flex-1 min-w-0">
                                    <span className="block text-base font-semibold text-gray-900 truncate dark:text-white">
                                        Dribbble account
                                    </span>
                                    <span className="block text-sm font-normal text-gray-500 truncate dark:text-gray-400">
                                        Not connected
                                    </span>
                                </div>
                                <div className="inline-flex items-center">
                                    <a href="#" className="px-3 py-2 mb-3 mr-3 text-sm font-medium text-center text-white rounded-lg bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Connect</a>
                                </div>
                            </div>
                        </li> */}

                    </ul>

                    {/* <div>
                        <button className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Save all</button>
                    </div> */}

                </div>
            </div>
        </>
    );
};

export default Social;