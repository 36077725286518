import { useRef } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import lottie from "lottie-web";

// Images && Logo
import ShineRanker from "../../src/constant/images/ShineRanker.png";
import { Tooltip } from "react-tooltip";
import { calculateRemainingTime } from "../pages/shinefy/Tools/functions/general";
import { countDataFromArray, treuncateTo2Decimal } from "../functions/general";
import consoleLog from "../functions/consoleLog";

export const SideNavMenuLink = ({ isLoading, path, icon, label, location, kind, type }) => {
    const navigate = useNavigate();
    const handleNavigate = () => {
        if (type === "link") {
            window.open(location, '_blank');
        } else {
            navigate(location);
        }
    };
    return (
        <li>
            {isLoading ? (<LoadingEffect1 />) : (
                <div onClick={handleNavigate} className={`${path === location && "bg-gray-300 dark:bg-gray-700"} cursor-pointer flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-300 dark:hover:bg-gray-700 group`}>
                    {kind === "image" ? (
                        <div className="dark:bg-purple-700 bg-purple-500 p-1 -ml-1 rounded-full">
                            <img decoding="async" data-nimg="intrinsic" src={ShineRanker} className="w-[1.4rem] h-[1.4rem]" alt="Image" />
                        </div>
                    ) : (
                        <i className={`${icon} text-xl text-gray-400 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white`} />
                    )}
                    <span className="ml-3 font-semibold">{label}</span>
                </div>
            )}
        </li>
    );
};

export const SideNavNotifications = ({ isLoading, path, icon, label, location, notif }) => {

    const navigate = useNavigate();
    const handleNavigate = () => {
        navigate(location);
    };

    // call fnction that counts the data conditionally
    const dataCount = countDataFromArray(notif, "isRead", false);

    // console log activiy
    // consoleLog("dataCount", dataCount);

    return (
        <li>
            {isLoading ? (<LoadingEffect1 />) : (
                <div onClick={handleNavigate} className={`${path === location && "bg-gray-300 dark:bg-gray-700"} cursor-pointer flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-300 dark:hover:bg-gray-700 group`}>
                    <i className={`${icon} text-xl text-gray-400 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white`} />
                    <span className="flex-1 ml-3 whitespace-nowrap">{label}</span>
                    {dataCount > 0 && (
                        <span className="inline-flex justify-center items-center w-5 h-5 rounded-full text-gray-10 font-sans text-sm font-semibold bg-red-400 dark:bg-red-500">
                            {Number(dataCount || 0).toLocaleString()}
                        </span>
                    )}
                </div>
            )}
        </li>
    );
};

export const SideNavMenuCedits = ({ isLoading, path, subscriptionData, icon, label, location }) => {

    const navigate = useNavigate();
    const handleNavigate = () => {
        navigate(location);
    };

    const tokenValue = JSON.parse(localStorage.getItem("tokenBalance"));

    return (
        <li>
            {isLoading ? (<LoadingEffect2 />) : (
                <div onClick={handleNavigate} className={`${path === location && "bg-gray-300 dark:bg-gray-700"} flex cursor-pointer items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-300 dark:hover:bg-gray-700 group`}>
                    <span className="flex w-full flex-row justify-between">
                        <span className="gold-new-button flex items-center gap-3 font-semibold">
                            <i className={`${icon} text-xl text-yellow-400 dark:text-yellow-300 transition duration-75`} />
                            {label}
                        </span>
                    </span>
                    <span
                        data-tooltip-id="tooltip-reset-remaining-time"
                        // data-tooltip-content="Hide advance option"
                        // data-tooltip-place="right-start"
                        data-tooltip-html={`
                            <p class="break-normal text-sm font-semibold">
                                ${calculateRemainingTime(subscriptionData?.status?.subscription_interval || subscriptionData?.status?.valid_till, "monthly_credits")}
                            </p>
                        `}
                        className="rounded-md bg-sky-400 py-0.5 px-1.5 font-extrabold text-xs text-gray-800"
                    >
                        {/* <code>{Number(credits + p_credits || p_credits || 0).toLocaleString()}</code> */}
                        {/* <code>{(Number(tokenValue?.value) || 0)?.toFixed(2)?.toLocaleString()}</code> */}
                        <code>{treuncateTo2Decimal(tokenValue?.value)}</code>
                        <Tooltip id="tooltip-reset-remaining-time" />
                    </span>
                </div>
            )}
        </li>
    );
};

export const SideNavMenuDropDown = ({ isLoading, path, icon, label, kind, dropDown, isHidden, setIsHidden, isHiddenFunction }) => {
    useEffect(() => {
        if (dropDown.some(item => item.location === path)) {
            setIsHidden(true);
        }
    }, []);
    const navigate = useNavigate();
    const handleNavigate = (location) => {
        navigate(location);
    };
    return (
        <li>
            {isLoading ? (<LoadingEffect2 />) : (
                <div>
                    <button
                        onClick={isHiddenFunction}
                        type="button"
                        aria-controls="dropdown-authentication"
                        data-collapse-toggle="dropdown-authentication"
                        className={`${dropDown.some(item => item.location === path || isHidden) && "bg-gray-300 dark:bg-gray-700"} flex items-center p-2 w-full text-base font-normal text-gray-900 rounded-lg transition duration-75 group hover:bg-gray-300 dark:text-white dark:hover:bg-gray-700`}
                    >
                        {kind === "image" ? (
                            <div className="dark:bg-purple-700 bg-purple-500 p-1 -ml-1 rounded-full">
                                <img decoding="async" data-nimg="intrinsic" src={ShineRanker} className="w-[1.4rem] h-[1.4rem]" alt="Image" />
                            </div>
                        ) : (
                            <i className={`${icon} text-xl flex-shrink-0 text-gray-400 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white`} />

                        )}

                        <span className="flex-1 ml-3 text-left whitespace-nowrap font-semibold">{label}</span>
                        {isHidden ? (
                            <i className="fa-solid fa-chevron-down px-1" />
                        ) : (
                            <i className="fa-solid fa-chevron-right px-1" />
                        )}

                    </button>
                    {isHidden && (
                        <ul id="dropdown-authentication" className="py-2 space-y-1">
                            {dropDown.map((item, index) => {

                                // console log activity
                                // consoleLog("path", path);
                                // consoleLog("item?.location", item?.location);

                                return (
                                    <li key={index}>
                                        <div onClick={() => handleNavigate(item?.location)} className={`${path === item?.location && "bg-gray-300 dark:bg-gray-700"} cursor-pointer flex items-center p-2 pl-11 w-full text-base font-normal text-gray-900 rounded-lg transition duration-75 group hover:bg-gray-300 dark:text-white dark:hover:bg-gray-700`}>
                                            <i className="fa-solid fa-circle text-xs text-green-700 mr-2" />
                                            {item?.label}
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>
                    )}
                </div>
            )}
        </li>
    );
};

export const LoadingEffect1 = () => {
    return (
        <div className="animate-pulse flex space-x-4">
            <div className="flex-1 space-y-6 py-1">
                <div className="space-y-3">
                    <div className="grid grid-cols-2 gap-4">
                        <div className="h-9 bg-gray-300 dark:bg-slate-700 rounded-xl col-span-2" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export const LoadingEffect2 = () => {
    return (
        <div className="animate-pulse flex space-x-4">
            <div className="flex-1 space-y-6 py-1">
                <div className="space-y-3">
                    <div className="grid grid-cols-3 gap-4">
                        <div className="h-9 bg-gray-300 dark:bg-slate-700 rounded-xl col-span-2" />
                        <div className="h-9 bg-gray-300 dark:bg-slate-700 rounded-xl col-span-1" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export const LoadingEffect3 = () => {
    return (
        <section className="h-screen bg-gradient-to-tr from-blue-800 to-purple-950">
            <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                <div className="mx-automax-w-screen-sm text-center mt-[30%]">

                    <div className="border border-gray-50 shadow rounded-md p-4 max-w-sm w-full mx-auto">
                        <div className="animate-pulse flex space-x-4">
                            <div className="rounded-full bg-slate-50 h-10 w-10" />
                            <div className="flex-1 space-y-6 py-1">
                                <div className="h-2 bg-slate-50 rounded" />
                                <div className="space-y-3">
                                    <div className="grid grid-cols-3 gap-4">
                                        <div className="h-2 bg-slate-50 rounded col-span-2" />
                                        <div className="h-2 bg-slate-50 rounded col-span-1" />
                                    </div>
                                    <div className="h-2 bg-slate-50 rounded" />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    );
};

export const LoadingEffect4 = () => {

    let loadingToolsAnimationContainer = useRef();

    useEffect(() => {

        const loadingToolsAnimation = lottie.loadAnimation({
            container: loadingToolsAnimationContainer.current,
            rerender: "svg",
            loop: true,
            autoplay: true,
            path: "/assets/shine-ai-robot-head.json",
        });

        return () => {
            loadingToolsAnimation.destroy();
            // loadingToolsAnimation.setSpeed(1.5);
        };
    }, [loadingToolsAnimationContainer]);

    return (
        <section className="h-screen bg-gradient-to-tr from-blue-800 to-purple-950">
            <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                <div className="mx-automax-w-screen-sm text-center mt-[30%]">

                    {/* <div className="border border-gray-50 shadow rounded-md p-4 max-w-sm w-full mx-auto">
                        <div className="animate-pulse flex space-x-4">
                            <div className="rounded-full bg-slate-50 h-10 w-10" />
                            <div className="flex-1 space-y-6 py-1">
                                <div className="h-2 bg-slate-50 rounded" />
                                <div className="space-y-3">
                                    <div className="grid grid-cols-3 gap-4">
                                        <div className="h-2 bg-slate-50 rounded col-span-2" />
                                        <div className="h-2 bg-slate-50 rounded col-span-1" />
                                    </div>
                                    <div className="h-2 bg-slate-50 rounded" />
                                </div>
                            </div>
                        </div>
                    </div> */}

                    <div className="w-auto h-60" ref={loadingToolsAnimationContainer} />

                </div>
            </div>
        </section>
    );
};