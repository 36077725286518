// libraries
import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

// custom components
import consoleLog from "../../functions/consoleLog";
import UserContext from "../../components/UserContext";

const SRPage = () => {

    // context method
    const {
        // iframe
        setIsLoadingIframe,
        isLoadingIframe,
    } = useContext(UserContext);

    // React Router hook to get the current location object
    const location = useLocation();

    // SR configs
    const tokenAccess = localStorage.getItem('SRAccesstoken'); // get SR access token from localstorage
    const url = process.env.REACT_APP_SHINERANKER_APP; // SR domain

    // react useRef
    const iframeRef = useRef(null);

    // console log activity
    // consoleLog("URLSRPage", URLSRPage);
    consoleLog("isLoadingIframe", isLoadingIframe);

    // UseEffect to watch for location (URL) changes
    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const page = urlParams.get("page");

        // Check if isLoadingIframe is false before executing handleFrameMessage
        if (!isLoadingIframe) {
            if (page) {
                handleFrameMessage(page);
                // Re-execute handleFrameMessage after 1 second
                setTimeout(() => {
                    handleFrameMessage(page);
                }, 250); // 1000 milliseconds = 1 second
            } else {
                handleFrameMessage('projectsdashboard');
            }

            // Log the page value
            consoleLog("page", page);
        }
    }, [location, isLoadingIframe]); // This will trigger when the location changes

    // function handle frame message
    const handleFrameMessage = (targetFrame) => {
        if (iframeRef.current) {
            iframeRef.current.contentWindow.postMessage(targetFrame, '*');
        }
    };

    return (
        <div>
            {/* Iframe Section */}
            <div id="ShinerankerMain" style={{ display: 'block' }}>
                <iframe
                    id="frame"
                    ref={iframeRef}
                    className="w-[100%] h-screen"
                    // style={{ width: '100%', height: '1800px', maxWidth: '1800px', border: '2px solid black', margin: 'auto', }}
                    // frameBorder="1"
                    src={`${url}/iframe/dashboard?token=${tokenAccess}`}
                    title="ShineRanker SEO Audit"
                    onLoad={() => {
                        setIsLoadingIframe(false);
                        consoleLog("iFrame", "Iframe is loaded");
                    }}
                />
            </div>
        </div>
    );
};

export default SRPage;